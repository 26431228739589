<template>
  <div style="padding: 10px;">
    <el-form ref="form" label-width="140px">
      <el-form-item label="项目名称">
        {{ info.name }}
      </el-form-item>

      <!-- <el-form-item label="项目代码">
        {{ info.code }}
      </el-form-item> -->

      <el-form-item label="资金年份">
        {{ info.zjnf }}
      </el-form-item>

      <el-form-item label="申请金额">
        {{ info.apply_fee }} 万元
      </el-form-item>

      <el-form-item label="项目金额">
        {{ info.fee }} 万元
      </el-form-item>

      <el-form-item label="支付金额">
        {{ info.paid_fee }} 万元
      </el-form-item>

      <el-form-item label="剩余资金">
        {{ remain_fee }} <span style="margin-left: 10px;">万元</span> <el-button v-if="remain_fee > 0" size="mini" style="margin-left: 10px;">资金整合</el-button>
      </el-form-item>

      <el-form-item label="资金池">
        {{ info.cash_pool_name }}
      </el-form-item>

      <el-form-item label="实施主体">
        {{ info.sszt }}
      </el-form-item>

      <el-form-item label="实施时间">

        <span v-if="info.sskssj && info.ssjssj">
          {{ info.sskssj | formatDate }} - {{ info.ssjssj | formatDate }}
        </span>
        
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';

export default {
  props: {
    info: {
      type: Object,
      default: () => null,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    remain_fee() {
      return BigNumber(this.info.fee).minus(this.info.paid_fee)
    }
  },
}
</script>